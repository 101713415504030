<template>
  <div class="d-flex" style="width: 100%">
    <v-autocomplete
      v-model="itemValidation.validationRuleId"
      outlined
      dense
      clearable
      hide-no-data
      :items="filteredValidations"
      item-text="name"
      item-value="id"
      :label="label || $lang.labels.validation"
      :placeholder="$lang.actions.startTyping"
      prepend-inner-icon="mdi-cloud-search-outline"
      :readonly="!userCanEdit"
      :data-cy="`field-genericSubTypeValidation-${itemIndex}`"
      :rules="required ? [v => !!v || $lang.labels.required] : []"
      :class="[required && 'required-asterisk', 'clickable-simple']"
      :search-input.sync="search"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title :data-cy="item.id">{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-btn
      text
      class="ml-1"
      color="primary"
      :disabled="(!itemValidation.validationRule || (itemValidation.validationRule && !itemValidation.validationRule.id)) && !itemValidation.validationRuleId"
      data-cy="open-validation"
      @click="openValidation(itemValidation.validationRuleId || itemValidation.validationRule.id)"
    >
      {{ $lang.actions.openTemplate }}
    </v-btn>
  </div>
</template>

<script>
import {
  getValidationRuleByIdUsingGET as getValidation,
  getValidationRulesUsingGET as getValidations
} from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  props: {
    itemValidation: {
      type: Object,
      default: () => {}
    },
    itemIndex: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    userCanEdit: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: true
    },
    resourceIsSystem: {
      type: Boolean,
      default: false
    }
  },
  inject: ['openValidation'],
  data() {
    return {
      filteredValidations: [],
      search: '',
      validationRuleId: this.itemValidation.validationRuleId
    }
  },
  watch: {
    search(val, oldVal) {
      if (!val) {
        this.filteredValidations = []

        return
      }

      if (this.itemValidation?.validationRule?.name && val === this.itemValidation.validationRule.name) {
        this.filteredValidations = [this.itemValidation.validationRule]

        return
      }
      if (val === oldVal) return

      this.searchValidations()
    },
    'itemValidation.validationRuleId': {
      handler(val) {
        this.$emit('validationRuleIdChanged', val)
      },
      immediate: true
    },
    filteredValidations(val) {
      if (!val || val.length < 1) return

      this.$emit('onChangeValidationSearchResults', val)
    }
  },
  mounted() {
    if (this.itemValidation.validationRuleId) {
      getValidation({ id: this.itemValidation.validationRuleId })
        .then((res) => {
          if (res.status === 200) {
            this.itemValidation.validationRule = res.data.data
          } else {
            this.itemValidation.validationRuleId = null
          }
        }).then(() => {
          if (this.itemValidation?.validationRule?.name) {
            this.search = this.itemValidation.validationRule.name
          }
        })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    searchValidations() {
      this.filteredValidations = []

      if (!this.search || !this.search.length || this.search.length <= 1) return
      this.itemValidation.isLoadingValidations = true

      getValidations({ name: this.search, isSystem: this.resourceIsSystem })
        .then((res) => {
          this.filteredValidations = res.data.data.items
          this.itemValidation.isLoadingValidations = false
        })
        .catch((err) => {
          this.itemValidation.isLoadingValidations = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    }
  }
}
</script>
